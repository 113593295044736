import { FC } from 'react';

import { Grid } from '@mui/material';
import _ from 'lodash';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import TypographyMsToText from '@/shared/ui/Typography/TypographyMsToText';
import { FieldInputNumber } from '@/shared/ui/Fields/components/FieldInputNumber/FieldInputNumber';
import Button from '@/shared/ui/Button';
import { RegistrableValues } from '@/shared/lib/types';
import {
  CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { useResolve } from '@/hooks/useResolve';

import {
  LOG_SETTINGS_COMPONENT_LOG_OPTIONS,
  LOG_SETTINGS_DATA_LEVEL_OPTIONS, LOG_SETTINGS_EXCLUDE_LOG_OPTIONS,
  LOG_SETTINGS_LEVEL_OPTIONS
} from './constants';
import { BlockService } from '../../services/BlockService';
import { LogSettings } from '../../types';
import { logSettingsValidationSchema } from './validationSchema';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { BlockMonitoringService } from '@/entities/Block/services/BlockMonitoringService';
import {FieldAutocomplete} from "@/shared/ui/Fields/components/FieldAutocomplete/FieldAutocomplete";

export const LogSettingsFormContainer: FC = observer(() => {
  const { flowId } = useParams();
  const { remove, get } = useSearchParamsTemplate();

  const blockService = useResolve(BlockService, { [RegistrableValues.FlowId]: flowId });
  const blockMonitoringService = container.resolve(BlockMonitoringService);
  const blockId = get(SearchParams.BlockId);

  const block = blockService.blocks?.entities[blockId];

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<LogSettings>({
    resolver: yupResolver(logSettingsValidationSchema),
    values: block?.logSettings || undefined,
    // defaultValues: logSettingsValidationSchema.cast(undefined) as any,
    mode: 'onChange',
  });

  const retentionTime = useWatch({
    control,
    name: 'retentionTime',
    defaultValue: block?.logSettings.retentionTime,
  });

  const level = useWatch({
    control,
    name: 'level',
    defaultValue: block?.logSettings.level,
  });

  const dataLevel = useWatch({
    control,
    name: 'dataLevel',
    defaultValue: block?.logSettings.dataLevel,
  });

  const excludeLog = useWatch({
    control,
    name: 'excludeLog',
    defaultValue: block?.logSettings.excludeLog,
  });

  const onSubmit: SubmitHandler<LogSettings> = (data) => {
    if (block) {
      blockService.updateBlock({ ...block, logSettings: data });
    }

    remove([
      SearchParams.ShowMessageDeliverySettings,
      SearchParams.ShowLogSettings,
      ...CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
    ]);
  };

  const clearError = async () => {
    await blockService.clearError(blockId);
  };

  const clearLog = async () => {
    await blockService.clearLog(blockId);
  };

  return (
    <form>
      <div className='col-span-12 px-5'>
        <Grid container gap={2.5}>
          <Grid xs={12} lg={12}>
            <FieldSelect
              control={control}
              label='Уровень логирования трассировки данных'
              options={LOG_SETTINGS_LEVEL_OPTIONS}
              error={_.get(errors, 'level')}
              name='level'
              defaultValue={level}
            />
          </Grid>
          <Grid xs={12} lg={12}>
            <FieldSelect
              control={control}
              label='Уровень логирования данных'
              options={LOG_SETTINGS_DATA_LEVEL_OPTIONS}
              error={_.get(errors, 'dataLevel')}
              name='dataLevel'
              defaultValue={dataLevel}
            />
          </Grid>
          <Grid xs={12} lg={12}>
            <FieldAutocomplete
              control={control}
              multiple
              label='Исключить из мониторинга'
              options={LOG_SETTINGS_EXCLUDE_LOG_OPTIONS}
              error={_.get(errors, 'excludeLog')}
              name='excludeLog'
              defaultValue={excludeLog}
            />
          </Grid>
          <Grid xs={12} lg={12}>
            <FieldInputNumber
              control={control}
              label='Время хранения в мс'
              error={_.get(errors, 'retentionTime')}
              name='retentionTime'
              step={86400000}
            />
          </Grid>
          <div className='col-span-12'>
            <span>Время хранения:</span> <TypographyMsToText time={retentionTime} />
          </div>
          <Grid container gap={2.5}>
            <Grid xs={5} lg={5}>
              <Button variant={ButtonVariants.Secondary} onClick={clearError}>
                Очистить ошибки
              </Button>
            </Grid>
            <Grid xs={5} lg={5}>
              <Button variant={ButtonVariants.Secondary} onClick={clearLog}>
                Очистить логи
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className='grid grid-cols-12 col-span-12 self-end pb-10 px-5'>
        <div className='col-span-3 col-start-10'>
          <Button onClick={handleSubmit(onSubmit)}>Сохранить</Button>
        </div>
      </div>
    </form>
  );
});
