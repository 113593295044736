import { makeAutoObservable, runInAction } from 'mobx';
import { injectable } from 'tsyringe';
import _ from 'lodash';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { notify } from '@/shared/ui/Toast/notify';
import { Attachment } from '@/features/UploadFiles/api/types';

import { FileUploadStore } from './FileUploadStore';

@injectable()
export class FileUploadService {
  constructor(private apiService: ApiService, private fileUploadStore: FileUploadStore) {
    makeAutoObservable(this);
  }

  get attachments() {
    return this.fileUploadStore.attachments;
  }

  get files() {
    return this.fileUploadStore.files;
  }

  get isLoadingFiles() {
    return this.fileUploadStore.isLoadingFiles;
  }

  setFiles(files: File[]) {
    if (files) {
      this.fileUploadStore.files = _.uniqBy([...this.fileUploadStore.files, ...files], 'name');
    }
  }

  removeFile(fileName: string) {
    runInAction(() => {
      this.fileUploadStore.files.splice(
        this.fileUploadStore.files.findIndex((file) => file.name === fileName),
        1
      );
    });
  }

  removeAttachment(fileName: string) {
    runInAction(() => {
      this.fileUploadStore.attachments.splice(
        this.fileUploadStore.attachments.findIndex(
          (attachment) => attachment.fileName === fileName
        ),
        1
      );
    });
  }

  setAttachments(attachments: Attachment[]) {
    if (attachments) {
      this.fileUploadStore.attachments = attachments;
    }
  }

  async uploadFiles(files: File[]) {
    this.fileUploadStore.isLoadingFiles = true;

    try {
      await Promise.allSettled(files.map((file) => this.uploadFile(file)));
    } finally {
      this.fileUploadStore.isLoadingFiles = false;
    }
  }

  private async uploadFile(file: File) {
    try {
      const response = await this.apiService.instance.postForm<Attachment>(`/editor/file/upload`, {
        file,
      });

      runInAction(() => {
        this.fileUploadStore.attachments.push(response.data);
        this.fileUploadStore.files.splice(
          this.fileUploadStore.files.findIndex((file) => file.name === response.data.fileName),
          1
        );
      });
    } catch (error) {
      notify.error(`Не удалось загрузить ${file.name}`);
      throw error;
    } finally {
      this.fileUploadStore.isLoadingFiles = false;
    }
  }
}
