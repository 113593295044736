import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import {DisplayMessageRequest, DisplayMessageResponse, ExecutorHistory} from '@/entities/Connect/types';
import { normalize } from '@/shared/lib/normalize';

import { DisplayMessageStore } from '../stores/DisplayMessageStore';
import {Tabs} from "@/widgets/MessageTracingWorkspace/lib/types";
import {findExecutorHistoryByTracing} from "@/shared/lib/executorHistoryUtils";

@singleton()
export class DisplayMessageService {
  constructor(private displayMessageStore: DisplayMessageStore, private apiService: ApiService) {
    makeAutoObservable(this);
  }

  get isLoadingMessages() {
    return this.displayMessageStore.isLoadingMessages;
  }

  get exchangeList() {
    return this.displayMessageStore.exchangeList;
  }

  get relationSet() {
    return this.displayMessageStore.relationSet;
  }

  get canvas() {
    return this.displayMessageStore.canvas;
  }

  get messageNumber() {
    return this.displayMessageStore.messageNumber;
  }

  get messageTotal() {
    return this.displayMessageStore.messageTotal;
  }

  get selectedExecutorHistoryId() {
    return this.displayMessageStore.selectedExecutorHistoryId;
  }

  get selectedExecutorHistory() {
    return this.displayMessageStore.selectedExecutorHistory;
  }

  get isResendLoading() {
    return this.displayMessageStore.isResendLoading;
  }

  get activeTab() {
    return this.displayMessageStore.activeTab;
  }

  get maxHeight() {
    return this.displayMessageStore.maxHeight;
  }

  get maxWidth() {
    return this.displayMessageStore.maxWidth;
  }

  get executorHistory() {
    return this.displayMessageStore.executorHistory;
  }

  get updateData() {
    return this.displayMessageStore.updateData
  }

  setActiveTab(tab:Tabs) {
    this.displayMessageStore.activeTab = tab
  }

  setExecutorHistory(executorHistory: ExecutorHistory) {
    this.setMessages(executorHistory)
  }

  reset() {
    this.displayMessageStore.canvas = null;
    this.displayMessageStore.messages = null;
    this.displayMessageStore.exchangeList = null;
    this.displayMessageStore.relationSet = [];
    this.displayMessageStore.messageNumber = 0;
    this.displayMessageStore.messageTotal = 0;
    this.displayMessageStore.selectedExecutorHistoryId = null;
    this.displayMessageStore.selectedExecutorHistory = null;
    this.displayMessageStore.isResendLoading = false;
    this.displayMessageStore.activeTab = Tabs.tracing
    this.displayMessageStore.maxHeight = undefined;
    this.displayMessageStore.maxWidth = undefined;
    this.displayMessageStore.executorHistory = undefined
  }

  setMessages(executorHistory: ExecutorHistory | null) {
    if (executorHistory != null) {
      const graphTrace = executorHistory.graphTrace;
      graphTrace.exchangeList.forEach(exchange => {
        if (exchange.key !== null && exchange.key !== undefined) {
          exchange.id = exchange.key
        }
      })
      this.displayMessageStore.exchangeList = normalize(graphTrace.exchangeList, 'id');
      if (graphTrace.data) {
        for (let entityId in this.displayMessageStore.exchangeList.entities) {
          let entity = this.displayMessageStore.exchangeList.entities[entityId]
          if (entity.inputConnectId) {
            // @ts-ignore
            entity.inputConnect = graphTrace.data[entity.inputConnectId]
          } else {
            if (entity.processorId) {
              // @ts-ignore
              entity.processor = graphTrace.data[entity.processorId]
            } else {
              if (entity.outputConnectId) {
                // @ts-ignore
                entity.outputConnect = graphTrace.data[entity.outputConnectId]
              }
            }
          }
        }
      }
      this.displayMessageStore.relationSet = graphTrace.relationSet || [];
      this.displayMessageStore.canvas = normalize(graphTrace.canvas.elements, 'elementId');
      this.displayMessageStore.maxHeight = graphTrace.canvas.height;
      this.displayMessageStore.maxWidth = graphTrace.canvas.width;
      this.displayMessageStore.selectedExecutorHistory = executorHistory
      this.displayMessageStore.selectedExecutorHistoryId = executorHistory.id;
      if (!executorHistory.executorLogId) {
        this.displayMessageStore.activeTab = Tabs.tracing
      }
    } else {
      this.displayMessageStore.exchangeList = null;
      this.displayMessageStore.relationSet = [];
      this.displayMessageStore.canvas = null;
      this.displayMessageStore.selectedExecutorHistory = null
      this.displayMessageStore.selectedExecutorHistoryId = null;
    }
  }

  setBodyData(exchangeId: string, value: string, type: string) {
    if (type === "inputBody") {
      this.displayMessageStore.exchangeList!.entities[exchangeId].inputBody.outputBody = value
      this.displayMessageStore.exchangeList!.entities[exchangeId].inputBody.isSuccessDownload = true
    } else {
      this.displayMessageStore.exchangeList!.entities[exchangeId].body.outputBody = value
      this.displayMessageStore.exchangeList!.entities[exchangeId].body.isSuccessDownload = true
    }
  }

  async getMessages(blockId: string, body: DisplayMessageRequest) {
    this.displayMessageStore.isLoadingMessages = true;

    try {
      const response = await this.apiService.instance.post<DisplayMessageResponse>(
        `/editor/displayMessage/block/${blockId}`,
        { ...body }
      );

      runInAction(() => {
        if (Array.isArray(response.data.executorHistoryPage.content) && response.data.executorHistoryPage.content.length > 0) {
          this.displayMessageStore.executorHistory = response.data.executorHistoryPage.content[0]
          if (response.data.executorHistoryPage.content[0].defaultExecutorHistoryIdByTracing) {
            this.setMessages(findExecutorHistoryByTracing(response.data.executorHistoryPage.content[0], response.data.executorHistoryPage.content[0].defaultExecutorHistoryIdByTracing))
          } else {
            this.setMessages(response.data.executorHistoryPage.content[0])
          }
          this.displayMessageStore.messageNumber = response.data.executorHistoryPage.number + 1;
          this.displayMessageStore.messageTotal = response.data.executorHistoryPage.totalElements;
        } else {
          this.reset()
        }
      });
    } catch (error) {
      notify.error('Не удалось получить трассировку сообщений');
      throw error;
    } finally {
      this.displayMessageStore.isLoadingMessages = false;
    }
  }

  async resendMessage(executorHistoryId: string) {
    this.displayMessageStore.isResendLoading = true;
    try {
      const response = await this.apiService.instance.post(`/editor/monitoring/execute/${executorHistoryId}`);

      runInAction(() => {
        this.setMessages(response.data)
      });
    } catch (error) {
      notify.error('Не удалось переотправить сообщение');
      throw error;
    } finally {
      this.displayMessageStore.isResendLoading = false;
    }
  }

  async downloadBodyData(exchangeId: string, bodyDataComponentId: string, type: string) {
    try {
      const response = await this.apiService.instance.get(`/editor/bodyData/${bodyDataComponentId}`);

      runInAction(() => {
        this.setBodyData(exchangeId, response.data.data, type)
        this.displayMessageStore.updateData = new Date().getTime()
      });
    } catch (error) {
      notify.error('Не удалось выполнить загрузку');
      throw error;
    }
  }
}
