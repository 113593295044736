import { FC, memo, MouseEventHandler, useCallback } from 'react';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Tooltip from '@/shared/ui/Tooltip';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';

import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

export interface ExitButtonProps {}

export const ExitButton: FC<ExitButtonProps> = memo(() => {
  const { set } = useSearchParamsTemplate();
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    set([SearchParams.ShowExitConfirmModal]);
  }, [set]);

  return (
    <Tooltip title='Выйти' disableInteractive>
      <span>
        <Button
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Medium}
          onClick={handleClick}
        >
          <PowerSettingsNewIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

ExitButton.displayName = 'ExitButton';
