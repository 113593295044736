import React, { FC, memo, useMemo } from 'react';

import MenuItem from '@mui/material/MenuItem';
import { isType } from '@/shared/lib/isType';
import { Input, InputProps } from '@/shared/ui';
import { SelectInputProps } from '@mui/material/Select/SelectInput';

import { SelectOption } from './types';

export interface SelectProps extends Omit<InputProps, 'onChange'> {
  options: SelectOption[] | string[];
  onChange: SelectInputProps['onChange'];
  multiple?: boolean;
  selectProps?: any;
}

export const Select: FC<SelectProps> = memo(({ options, value, onChange, hidden = false, multiple = false, selectProps = {}, ...props }) => {
  const SelectProps = useMemo(() => {
    return {
      value,
      onChange,
      multiple,
      ...selectProps
    };
  }, [onChange, value, multiple, selectProps]);

  if (!hidden) {
    return (
        <Input {...props} select SelectProps={SelectProps}>
          {options.map((option) => {
            const isOptionEqualSelectOptionType = isType<SelectOption>(option, 'value');
            const value = isOptionEqualSelectOptionType ? option.value : option;
            const label = isOptionEqualSelectOptionType ? option.label : option;

            return (
                <MenuItem key={value} value={value} style={{
                  margin:"0.15rem 0 0.15rem 0"
                }}>
                  {label}
                </MenuItem>
            );
          })}
        </Input>
    );
  } else {
    return null;
  }
});

Select.displayName = 'Select';
