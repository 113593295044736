import {makeAutoObservable, runInAction} from 'mobx';
import { injectable } from 'tsyringe';
import { AxiosResponse } from 'axios';
import { uniq } from "lodash";

import { ApiService } from '@/shared/api/Api/services/ApiService';
import { notify } from '@/shared/ui/Toast/notify';
import { BlockMiniStore } from "@/entities/Block/stores/BlockMiniStore";
import { normalize } from "@/shared/lib/normalize";

import Block from '../types';

@injectable()
export class BlockMiniService {
  constructor(
    private blockMiniStore: BlockMiniStore,
    private apiService: ApiService
  ) {

    makeAutoObservable(this);
  }

  get isLodingMiniBlocks() {
    return this.blockMiniStore.isLoading;
  }

  get miniBlocks() {
    return this.blockMiniStore.miniBlocks;
  }

  getBlocksByFlow(flowId: string) {
    const { miniBlocks } = this;
    return miniBlocks.ids.filter((id) => miniBlocks.entities[id]?.flowId === flowId);
  }

  async getAllMiniBlocks() {
    this.blockMiniStore.isLoading = true;

    try {
      const response = await this.apiService.instance.get<Block[], AxiosResponse<Block[]>>(
        `editor/block/allMini`
      );

      const normalizedBlockMini = normalize(response.data, 'id');

      runInAction(() => {
        if (!this.miniBlocks) {
          this.blockMiniStore.miniBlocks = normalizedBlockMini;
          return;
        }

        this.blockMiniStore.miniBlocks.ids = uniq([...this.blockMiniStore.miniBlocks.ids, ...normalizedBlockMini.ids]);
        this.blockMiniStore.miniBlocks.entities = {
          ...this.blockMiniStore.miniBlocks.entities,
          ...normalizedBlockMini.entities,
        };
      });

    } catch (error) {
      throw error;
    } finally {
      this.blockMiniStore.isLoading = false;
    }
  }

}
