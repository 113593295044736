import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { ConnectService } from '@/entities/Connect/services/ConnectService';

import { ApiService } from '../../../shared/api/Api/services/ApiService';
import { ConnectStore } from '../../../entities/Connect/stores/ConnectStore';

@singleton()
export class ToggleConnectorService {
  constructor(
    private apiService: ApiService,
    private connectorStore: ConnectStore,
    private connectService: ConnectService
  ) {
    makeAutoObservable(this);
  }

  async toggleConnector(blockId: string, connectorId: string, connectorType: string) {
    switch (connectorType) {
      case 'input': {
        const connector = this.connectorStore.inputList?.[blockId].entities[connectorId];

        if (connector) {
          connector.isActive = !connector.isActive;
        }

        break;
      }
      case 'processor': {
        const connector = this.connectorStore.processorList?.[blockId].entities[connectorId];

        if (connector) {
          connector.isActive = !connector.isActive;
        }

        break;
      }
      case 'exProcessor': {
        const connector = this.connectorStore.exProcessorList?.[blockId].entities[connectorId];

        if (connector) {
          connector.isActive = !connector.isActive;
        }

        break;
      }
      case 'output': {
        const connector = this.connectorStore.outputList?.[blockId].entities[connectorId];

        if (connector) {
          connector.isActive = !connector.isActive;
        }

        break;
      }
    }

    this.connectService.emitChangeConnects(blockId);
  }
}
