import { FC, useCallback, useEffect } from 'react';

import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { Stack } from '@mui/system';
import { FileField } from '@/shared/ui';
import { Label } from '@/shared/ui/Label/Label';
import { Parameter } from '@/entities/Connect/types';
import { Attachment } from '@/features/UploadFiles/api/types';
import { useResolve } from '@/hooks/useResolve';

import { FileUploadService } from '../api/FileUploadService';

export interface UploadFilesProps {
  attachments: Attachment[];
  parameter: Parameter;
  onChange: (flies: Attachment[]) => void;
}

export const UploadFiles: FC<UploadFilesProps> = observer(
  ({ attachments, parameter, onChange }) => {
    const draggable = _.get(parameter, 'props.draggable', false);
    const fileUploadService = useResolve(FileUploadService);

    const handleDownload = useCallback(
      (files: File[]) => {
        fileUploadService.uploadFiles(files).finally(() => {
          if (fileUploadService.files) {
            onChange(fileUploadService.attachments);
          }
        });
      },
      [fileUploadService, onChange]
    );

    const handleDrop = useCallback(
      (files: File[]) => {
        fileUploadService.setFiles(files);
      },
      [fileUploadService]
    );

    const handleRemove = useCallback(
      (fileName: string, key: string) => {
        if (key === 'attachments') {
          fileUploadService.removeAttachment(fileName);
        }
        if (key === 'files') {
          fileUploadService.removeFile(fileName);
        }
      },
      [fileUploadService]
    );

    useEffect(() => {
      fileUploadService.setAttachments(attachments);
    }, [attachments]);

    useEffect(() => {
      onChange(fileUploadService.attachments);
    }, []);

    return (
      <Stack gap={2}>
        <Label tooltip={parameter?.description}>{parameter.label}</Label>
        <FileField
          downloading={fileUploadService.isLoadingFiles}
          files={fileUploadService.files}
          attachments={fileUploadService.attachments}
          onDownload={handleDownload}
          onDrop={handleDrop}
          onRemove={handleRemove}
        />
      </Stack>
    );
  }
);

UploadFiles.displayName = 'UploadFiles';
