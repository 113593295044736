import {makeAutoObservable, runInAction} from 'mobx';
import {inject, injectable} from 'tsyringe';
import {normalize, Normalized} from '@/shared/lib/normalize';
import {eventEmitter} from '@/shared/api/EventEmitter/EventEmitter';
import {CustomEvents} from '@/shared/api/EventEmitter/types';
import {AxiosResponse} from 'axios';
import {ApiService} from '@/shared/api/Api/services/ApiService';
import {notify} from '@/shared/ui/Toast/notify';
import {RegistrableValues} from '@/shared/lib/types';
import {DEFAULT_BLOCK} from '@/entities/Block/constants';

import Block, {Exchange} from '../types';
import {BlockStore} from '../stores/BlockStore';
import {SendMessageStore} from '../stores/SendMessageStore';

@injectable()
export class BlockService {
    constructor(
        @inject(RegistrableValues.FlowId) private flowId: string,
        private blockStore: BlockStore,
        private apiService: ApiService,
        private sendMessageStore: SendMessageStore
    ) {
        this.blockStore.flowId = flowId;

        makeAutoObservable(this);
    }

    get blocks(): Normalized<Block> | null {
        return this.blockStore.blocks?.[this.flowId] || null;
    }

    get sendMessageResponse() {
        return this.blockStore.sendMessageResponse;
    }

    set sendMessageResponse(sendMessageResponse) {
        this.blockStore.sendMessageResponse = sendMessageResponse;
    }

    get sendMessageRequest() {
        return this.blockStore.sendMessageRequest;
    }

    // get isGuaranteedDelivery(blockId){
    //   return this.blocks.entities[blockId]
    // }

    set sendMessageRequest(sendMessageRequest) {
        this.blockStore.sendMessageRequest = sendMessageRequest;
    }

    get nodeIdStartConnect() {
        return this.blockStore.nodeIdStartConnect;
    }

    set nodeIdStartConnect(nodeIdStartConnect) {
        this.blockStore.nodeIdStartConnect = nodeIdStartConnect;
    }

    get isVisibleConnectionLine() {
        return this.blockStore.isVisibleConnectionLine;
    }

    set isVisibleConnectionLine(isVisibleConnectionLine) {
        this.blockStore.isVisibleConnectionLine = isVisibleConnectionLine;
    }

    get isVisiblePanelChooseConnects() {
        return this.blockStore.isVisiblePanelChooseConnects;
    }

    set isVisiblePanelChooseConnects(isVisiblePanelChooseConnects) {
        this.blockStore.isVisiblePanelChooseConnects = isVisiblePanelChooseConnects;
    }

    get isLoadingSendMessage() {
        return this.blockStore.isLoadingSendMessage;
    }

    createBlock(block: Block): void {
        if (this.blockStore.blocks) {
            const createdBlock = {...DEFAULT_BLOCK, ...block};
            this.blockStore.blocks[this.flowId].ids.push(block.id);
            this.blockStore.blocks[this.flowId].entities[block.id] = createdBlock;
            this.blockStore.isVisiblePanelChooseConnects = false;
            eventEmitter.emit(CustomEvents.CreateBlock, block);
        }
    }

    updateBlock(block: Block): void {
        eventEmitter.emit(CustomEvents.UpdateBlock);
        if (this.blockStore.blocks) {
            if (block.isManyToOne) {
                this.blockStore.blocks[this.flowId].entities[block.id] = {
                    ...block,
                    inputList:
                        block.inputList?.map((inputItem) => ({
                            ...inputItem,
                            isManyToOne: true,
                        })) || [],
                };
            }
            this.blockStore.blocks[this.flowId].entities[block.id] = block;
        }
    }

    deleteBlock(blockId: string): void {
        eventEmitter.emit(CustomEvents.DeleteBlock, this.flowId, blockId);

        this.blockStore.blocks?.[this.flowId].ids.splice(
            this.blockStore.blocks[this.flowId].ids.indexOf(blockId),
            1
        );
        delete this.blockStore.blocks?.[this.flowId].entities[blockId];
    }

    async copyBlock(block: Block, flowId: string) {
        try {
            const response = await this.apiService.instance.post<Block, AxiosResponse<Block>>(
                `editor/block/copy?flowId=${flowId}`,
                block
            );
            this.blockStore.copyBlockNew = response.data;
            return this.blockStore.copyBlockNew;
        } catch (error) {
            notify.error('Не удалось скопировать блок');
            throw error;
        }
    }

    async sendMessage(data: Exchange) {
        this.blockStore.isLoadingSendMessage = true;

        try {
            const response = await this.apiService.instance.post<Exchange, AxiosResponse<Exchange>>(
                'editor/block/sendMessage',
                data
            );

            runInAction(() => {
                this.blockStore.sendMessageResponse = response.data;

                if (response.data.executorHistory) {
                    this.sendMessageStore.relationSet = response.data.executorHistory.graphTrace.relationSet;
                    this.sendMessageStore.canvas = normalize(
                        response.data.executorHistory.graphTrace.canvas.elements,
                        'elementId'
                    );
                    response.data.executorHistory.graphTrace.exchangeList.forEach(exchange => {
                        exchange.id = exchange.key
                    })
                    this.sendMessageStore.exchangeList = normalize(
                        response.data.executorHistory.graphTrace.exchangeList,
                        'id'
                    );
                    this.sendMessageStore.exception = response.data.exception;
                }
            });
        } catch (error) {
            notify.error('Не удалось отправить сообщение');
            throw error;
        } finally {
            this.blockStore.isLoadingSendMessage = false;
        }
    }

    async clearError(blockId: string) {
        try {
            return await this.apiService.instance.delete<Exchange, AxiosResponse<Exchange>>(
                `editor/block/${blockId}/clearError`
            );
        } catch (error) {
            notify.error('Не удалось очистить ошибки');
            throw error;
        } finally {
            notify.success('Запущена очистка ошибок');
        }
    }

    async clearLog(blockId: string) {
        try {
            return await this.apiService.instance.delete<Exchange, AxiosResponse<Exchange>>(
                `editor/block/${blockId}/clearLog`
            );
        } catch (error) {
            notify.error('Не удалось очистить логи');
            throw error;
        } finally {
            notify.success('Запущена очистка логов');
        }
    }
}
