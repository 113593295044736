import React, {FC, memo, useCallback, useEffect, useState} from 'react';

import {Divider} from '@mui/material';
import {container} from "tsyringe";
import {ExecutorLogService, ExecutorLogStore} from "@/entities/ExecutorLog";
import TypographyDate from "@/shared/ui/Typography/TypographyDate";
import {Loader} from "@/shared/ui/Loader/Loader";
import Typography from "@/shared/ui/Typography";

import styles from './styles.module.scss';
import {Select} from "@/shared/ui";
import Button from "@/shared/ui/Button";

const executorLogService = container.resolve(ExecutorLogService);
const executorLogStore = container.resolve(ExecutorLogStore);

export interface ExecutorLogByIntegraProps {

}

export const ExecutorLogByIntegraView: FC<ExecutorLogByIntegraProps> = memo(
    ({
     }) => {

        let [rerender, setRerender] = useState(false)
        const [typeList, setTypeList] = useState(["Block"])

        useEffect(() => {
            find()
        }, [typeList])

        const find = () => {
            executorLogService.search(typeList)
                .then(() => {
                    setRerender(!rerender)
                })
        }

        const onChangeType = useCallback((e) => {
            setTypeList(e.target.value)
        }, [setTypeList]);

        return (
            <>
                <div className={styles.panel_filters}>
                    <Select
                        options={[{
                            value: "App",
                            label: "События по работе приложения"
                        }, {
                            value: "Block",
                            label: "События по работе блока"
                        }, {
                            value: "Message",
                            label: "События по сообщениям в блоке"
                        }]}
                        onChange={onChangeType}
                        multiple
                        value={typeList}
                    />
                    <Button onClick={() => find()} className={styles.reload_button}>
                        Обновить
                    </Button>
                </div>
                {!executorLogStore.isLoadingByIntegra ? <div className={styles.root}>
                        {executorLogStore.executorLogByIntegra.map(executorLog => {
                            return (
                                <>
                                    {executorLog?.messageLogs.map(message => {
                                        return <>
                                            <div className={styles.message}>
                                                <div className={styles.time}>
                                                    <TypographyDate time={message.createDate}/>
                                                </div>
                                                <span>:</span>
                                                <span className={styles.name}>
                                                <Typography>[{message.name}]</Typography>
                                            </span>
                                                <span>:</span>
                                                <div className={styles[message.level]}>
                                                    <Typography className={styles.text}>
                                                        {message.text}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </>
                                    })}
                                    <Divider/>
                                </>
                            )
                        })}
                    </div> :
                    <Loader/>
                }
            </>
        );
    }
);

ExecutorLogByIntegraView.displayName = 'ExecutorLogByIntegraView';
