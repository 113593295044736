import { FC } from 'react';

import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { FlowService } from '@/entities/Flow/services/FlowService';
import { RoutePaths } from '@/shared/lib/types';

import { routePathsDictionary } from './constants';
import { RoutePathsWithoutMain } from './types';

import styles from './Navigation.module.scss';

const routeKeys = Object.keys(routePathsDictionary) as RoutePathsWithoutMain[];

const joyrideService = container.resolve(JoyRideService);
const flowService = container.resolve(FlowService);

export const Navigation: FC = observer(() => {
  const { selectedFlowId } = flowService;
  const handleNavLinkClick = () => {
    if (joyrideService.run) {
      joyrideService.updateCurrentStep(joyrideService.stepIndex + 1);
    }
  };

  return (
    <nav className={styles.wrapper}>
      <ul className={styles.list}>
        {routeKeys.map((key) => {
          const route = routePathsDictionary[key];

          const to = selectedFlowId && key === RoutePaths.Flow
            ? `/${key}/${selectedFlowId}`
            : `/${key}`;

          return (
            <li key={key} id={key}>
              <NavLink
                onClick={handleNavLinkClick}
                to={to}
                className={({ isActive }) => cn(styles.element, [{ [styles.active]: isActive }])}
              >
                {route}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
});
