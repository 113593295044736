import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { notify } from '@/shared/ui/Toast/notify';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';
import {
  PythonInstanceRequest,
  PythonLibFilters,
  PythonLibsRequest,
} from '@/entities/PythonLib/stores/types';

enum ERequestInstallLib {
  status_code1 = 200,
  status_code2 = 201,
}

const ERequestInstallLibNotification: { [key: string]: string } = {
  [ERequestInstallLib.status_code1]: 'уже установлена',
  [ERequestInstallLib.status_code2]: 'установлена',
};
export const notifyF = (lib: string, code: number) =>
  notify.success(`Библиотека ${lib} ${ERequestInstallLibNotification[code]} `);

@singleton()
export class PythonLibsService {
  isLoading: boolean;

  showAddForm: boolean;

  showEditForm: boolean;

  showDialog: boolean;

  errorStatus: number;

  filteredLibs: PythonLibsRequest[] | [];

  constructor(private apiService: ApiService, private pythonLibStore: PythonLibsStore) {
    this.isLoading = false;
    this.showAddForm = false;
    this.showEditForm = false;
    this.showDialog = false;
    this.errorStatus = null;
    this.filteredLibs = [];

    makeAutoObservable(this);
  }

  async getPythonLibs() {
    this.isLoading = true;
    try {
      const response = await this.apiService.instance.get<PythonLibsRequest[]>(
        `/editor/python/api/management/get_libs?mode=${this.pythonLibStore.mode}`
      );
      this.pythonLibStore.libs = response.data;
    } catch (error: any) {
      if (
        this.errorStatus === 502 ||
        this.errorStatus === 426 ||
        this.errorStatus === 503 ||
        this.errorStatus === 500 ||
        this.errorStatus === 306
      ) {
        await this.getPythonLibs();
        await new Promise((resolve) => {
          setTimeout(resolve, 5000);
        });
        await this.getPythonLibs();
      } else {
        await this.getPythonLibs();
      }
    } finally {
      this.isLoading = false;
    }
  }

  async getMonitoringPods(status: string) {
    this.pythonLibStore.isLoadingInstance = true;
    try {
      const response = await this.apiService.instance.get<PythonInstanceRequest>(
        `/editor/python/api/replication/instances?exclude_statuses=${status}`
      );
      this.pythonLibStore.pods = response?.data;
    } catch (error: any) {
      if (
        error.response?.status !== 426 ||
        error.response?.status !== 503 ||
        error.response?.status !== 306
      ) {
        notify.error('Не удалось получить данные по кластеру');
        throw error;
      }
    } finally {
      this.pythonLibStore.isLoadingInstance = false;
    }
  }

  async installLib(title: string, version: string) {
    this.pythonLibStore.isLoadingInstall = true;
    try {
      const response = await this.apiService.instance.post<any>(
        `/editor/python/api/management/install?library=${title}&version=${version}`
      );
      if (response) {
        notifyF(title, response.data.status_code);
        await this.getPythonLibs();
      }
      await this.getPythonLibs();
    } catch (error: any) {
      if (
        error.response.status === 426 ||
        error.response.status === 503 ||
        error.response.status === 306
      ) {
        throw error;
      } else {
        notify.error(`Библиотека ${title} не найдена`);
      }
    } finally {
      this.pythonLibStore.isLoadingInstall = false;
    }
  }

  async installManyLib(data: any) {
    this.pythonLibStore.isLoadingInstall = true;
    try {
      const response = await this.apiService.instance.post<any>(
        `/editor/python/api/management/install_many`,
        data
      );
      if (response) {
        notify.success(`Библиотеки установлены`);
      }
      this.getPythonLibs();
    } catch (error: any) {
      if (
        error.response.status !== 426 ||
        error.response.status !== 503 ||
        error.response.status !== 306
      ) {
        notify.error(`Библиотека ${data.title} не найдена`);
        throw error;
      }
    } finally {
      this.pythonLibStore.isLoadingInstall = false;
    }
  }

  async deletePythonLib(library: string) {
    this.pythonLibStore.isLoadingDelete = true;
    try {
      await this.apiService.instance.post<any>(
        `/editor/python/api/management/uninstall?library=${library}`
      );
      notify.success(`${library} успешно удалена`);
      this.getPythonLibs();
    } catch (error: any) {
      if (
        error.response.status !== 426 ||
        error.response.status !== 503 ||
        error.response.status !== 306
      ) {
        notify.error(`Не удалось удалить ${library}`);
        throw error;
      }
    } finally {
      this.pythonLibStore.isLoadingDelete = false;
    }
  }

  setFilteredLibs(data: PythonLibFilters) {
    const filteredData: PythonLibsRequest[] = [];
    this.filteredLibs = this.pythonLibStore.libs?.data?.filter((item: PythonLibsRequest) => {
      if (data.title.some((el: string) => el === item.title)) {
        filteredData.push(item);
        return filteredData;
      }
      return null;
    });

    return this.filteredLibs;
  }

  resetFilter() {
    this.filteredLibs = null;
  }
}
