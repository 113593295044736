import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderOpenIcon from '@mui/icons-material/FolderOpenOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import { MenuItem } from '@/shared/ui';
import Icon7 from './Icon7';

type GuideProps = {
  slug: string;
  path: string;
  title: string;
};
const Guide: FC<GuideProps> = ({ slug, path, title }) => (
  <MenuItem key={slug} className='guide'>
    <ListItemIcon>
      <Icon7 size={20} />
    </ListItemIcon>
    <Link aria-label={slug} to={path} target='_blank' rel='noreferrer'>
      {title}
    </Link>
  </MenuItem>
);

export function Documents() {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);
  const Folder = open ? FolderOpenIcon : FolderIcon;

  return (
    <>
      <MenuItem key='documents' onClick={handleToggle}>
        <ListItemIcon>
          <Folder fontSize='small' />
        </ListItemIcon>
        <ListItemText>Документация</ListItemText>
      </MenuItem>
      {open && (
        <>
          <Divider />
          <Guide
            slug='user-manual'
            path='/user_manual_19.11.24.pdf'
            title='Руководство по эксплуатации (РЭ)'
          />
          <Guide
            slug='user-guide'
            path='/user_guide_18.11.24.pdf'
            title='Руководство пользователя (РП)'
          />
          <Guide
            slug='admin-guide'
            path='/admin_guide_22.11.24.pdf'
            title='Руководство администратора (РА)'
          />
        </>
      )}
    </>
  );
}
