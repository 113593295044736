import React, { FC, useCallback } from 'react';

import { bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import { PopupState, usePopupState } from 'material-ui-popup-state/hooks';
import { ToggleGuaranteedMenuItem } from '@/features/ToggleGuaranteed/ui/ToggleGuaranteed';

export interface ToggleGuaranteedMenuItemProps {
  blockId: string;
  type?: string;

  onClick?(): void;
}

interface GuaranteedContextMenuProps extends ToggleGuaranteedMenuItemProps {
  children: (popupState: any) => JSX.Element;
}

export const GuaranteedContextMenu: FC<GuaranteedContextMenuProps> = ({
  children,
  blockId,
  type,
}) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'demo-popup-menu' });
  const handleToggle = useCallback(
    (popupState: PopupState) => () => {
      popupState.close();
    },
    []
  );
  return (
    <>
      {children(popupState)}
      <Menu {...bindMenu(popupState)} elevation={0}>
        <ToggleGuaranteedMenuItem
          blockId={blockId}
          type={type}
          onClick={handleToggle(popupState)}
        />
      </Menu>
    </>
  );
};
