import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef, LiHTMLAttributes } from 'react';

import { TagSizes } from '@/shared/ui/Tag/types';

import styles from './Tag.module.scss';
import cn from 'classnames';

export interface TagProps
  extends Omit<DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>, 'ref'> {
  children: JSX.Element;
  size?: TagSizes;
  draggable?: boolean;
  isHighlight?: boolean;
  isHighlightManyToOne?: string;
  draggableProps?: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
  inactive?: boolean;
  isGuaranteed?: boolean;
}

export const Tag = forwardRef<HTMLLIElement, TagProps>(
  (
    {
      children,
      size = TagSizes.Large,
      className,
      draggable,
      isHighlight,
      isHighlightManyToOne,
      draggableProps,
      inactive,
      isGuaranteed,
      ...props
    },
    ref
  ) => {
    const isError = isHighlightManyToOne === 'Невалидный коннектор';
    return (
      <li className='flex' ref={ref} {...props}>
        <button
          tabIndex={1}
          className={cn(
            styles.button,
            {
              [styles.large]: size === TagSizes.Large,
              [styles.small]: size === TagSizes.Small && !isGuaranteed,
              [styles.highlight]: isHighlight,
              [styles.draggable]: draggable,
              [styles.inactive]: inactive,
              [styles.isHighlightManyToOne]: isError,
            },
            className
          )}
        >
          {children}
        </button>
        {draggable && (
          <button
            {...draggableProps}
            type='button'
            className={styles.hook}
            onFocus={() => void 0}
            onMouseOver={(event) => event.stopPropagation()}
            onPointerDown={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      </li>
    );
  }
);
