import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '@/shared/api/EventEmitter/types';
import { Block } from '@/entities/Block/types';
import { normalize, Normalized } from '@/shared/lib/normalize';

import { Connect, Processor } from '../types';

interface ConnectsByBlock<T = Connect> {
  [blockId: string]: Normalized<T>;
}

@singleton()
export class ConnectStore {
  inputList: ConnectsByBlock | null;
  processorList: ConnectsByBlock<Processor> | null;
  exProcessorList: ConnectsByBlock<Processor> | null;
  outputList: ConnectsByBlock | null;
  unsavedChanges: Connect | null;
  areThereChanges: boolean;

  constructor() {
    this.inputList = {};
    this.processorList = {};
    this.exProcessorList = {};
    this.outputList = {};
    this.unsavedChanges = null;
    this.areThereChanges = false;

    eventEmitter.on(CustomEvents.InitializeBlocks, (blocks: Block[]) => {
      blocks.forEach((block) => {
        this.setConnects(block);
      });
    });

    eventEmitter.on(CustomEvents.CreateBlock, this.setConnects.bind(this));

    makeAutoObservable(this);
  }

  private setConnects(block: Block) {
    if (this.inputList) {
      this.inputList[block.id] = normalize(block.inputList || [], 'id');
    } else {
      this.inputList = { [block.id]: normalize(block.inputList || [], 'id') };
    }

    if (this.processorList) {
      this.processorList[block.id] = normalize(block.processorList || [], 'id');
    } else {
      this.processorList = { [block.id]: normalize(block.processorList || [], 'id') };
    }

    if (this.exProcessorList) {
      this.exProcessorList[block.id] = normalize(block.exProcessorList || [], 'id');
    } else {
      this.exProcessorList = { [block.id]: normalize(block.exProcessorList || [], 'id') };
    }

    if (this.outputList) {
      this.outputList[block.id] = normalize(block.outputList || [], 'id');
    } else {
      this.outputList = { [block.id]: normalize(block.outputList || [], 'id') };
    }
  }
}
