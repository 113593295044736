import React from "react";

import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import { get } from 'lodash';
import { SwitchBaseProps } from "@mui/material/internal/SwitchBase";

import { Autocomplete } from '@/shared/ui/Autocomplete/Autocomplete';
import { DashboardService } from "@/entities/Dashboard/services/DashboardService";
import { FlowService } from "@/entities/Flow/services/FlowService";
import { BlockMiniService } from "@/entities/Block/services/BlockMiniService";
import { Radio } from "@/shared/ui/Radio/Radio";

import { defaultPanelData } from "./CounterStatistic";

const dashboardService = container.resolve(DashboardService);
const flowService = container.resolve(FlowService);
const blockMiniService = container.resolve(BlockMiniService);

export interface CounterPanelSettingsProps {
  dashboardPanelId: string;
}

export const Settings = observer((props: CounterPanelSettingsProps) => {
  const { dashboardPanelId } = props;

  const { flows } = flowService;
  const { miniBlocks } = blockMiniService;

  const panelData = dashboardService.getPanelData(dashboardPanelId);

  const values = panelData || defaultPanelData;
  // while backend don't clean idList on remove block/s
  const blockIdList = values.blockIdList.filter((id: string) => miniBlocks.entities[id]);

  const { messageType = 'all' } = values;
  const flow = flows.entities[values.flowId]; // cause flow by values.flowId mb removed
  const flowId = get(flow, 'id', '');

  const onChangeGraphType: SwitchBaseProps['onChange'] = ({ target: { value } }, checked) => {
    if (!checked) return;
    const data = {
      messageType: value,
    };
    dashboardService.changeDashboardPanel(dashboardPanelId, { data });
  }

  const onChangeFlowId = (_: any, flowId: string) => {
    const data = {
      flowId,
      blockIdList: blockMiniService.getBlocksByFlow(flowId),
    };
    dashboardService.changeDashboardPanel(dashboardPanelId, { data });
  }

  const onChangeBlockIdList = (newList: string[]) => {
    const data = { flowId: '', blockIdList: newList };
    dashboardService.changeDashboardPanel(dashboardPanelId, { data })
  }

  const getLabelFlow = (id: string) => {
    if (!id) return '';
    const { name } = flows.entities[id];
    return name;
  }

  const getLabelBlock = (id: string) => {
    const { name, flowName } = miniBlocks.entities[id];
    return `${flowName} - ${name}`;
  }

  const renderSelectGraphType = () => (
    <>
      <p>Количество сообщений</p>
      <Radio
        id="all"
        value="all"
        checked={messageType === 'all'}
        onChange={onChangeGraphType}
        label="полная информация"
      />
      <Radio
        id="started"
        value="started"
        checked={messageType === 'started'}
        onChange={onChangeGraphType}
        label="общее количество"
      />
      <Radio
        id="success"
        value="success"
        checked={messageType === 'success'}
        onChange={onChangeGraphType}
        label="успешно обработанные"
      />
      <Radio
        id="errors"
        value="errors"
        checked={messageType === 'errors'}
        onChange={onChangeGraphType}
        label="с ошибками"
      />
    </>
  )

  const renderSelectFlow = () => {
    if (!flows.ids.length) return null;

    return (
      <Autocomplete
        label="Потоки"
        value={flowId}
        options={flows.ids}
        autoComplete
        freeSolo
        filterSelectedOptions
        onChange={onChangeFlowId}
        renderOption={(props: any, id) => (
          <li {...props} key={id} value={id}>
            {getLabelFlow(id)}
          </li>
        )}
        getOptionLabel={getLabelFlow}
      />
    )
  }

  const renderSelectBlockIdList = () => {
    if (!miniBlocks) return null;

    return (
      <Autocomplete
        label="Блоки"
        value={blockIdList || []}
        options={miniBlocks.ids}
        autoComplete
        freeSolo
        multiple
        filterSelectedOptions
        onChange={(event, value) => {
          onChangeBlockIdList(value);
        }}
        renderOption={(props: any, id) => (
          <li {...props} key={id} value={id}>
            {getLabelBlock(id)}
          </li>
        )}
        getOptionLabel={getLabelBlock}
      />
    )
  }

  return (
    <>
      {renderSelectGraphType()}
      {renderSelectFlow()}
      {renderSelectBlockIdList()}
    </>
  )
});
