import React from 'react';

import { container } from 'tsyringe';
import { capitalize, get } from 'lodash';
import { observer } from "mobx-react-lite";

import { DashboardService } from '@/entities/Dashboard/services/DashboardService';
import { panelTitles } from "@/entities/Dashboard/widgets/DashboardPlatform/constants/titles";

import { CounterPanel } from './CounterPanel';
import { CounterStatistic } from './CounterStatistic';
import { WithHeader } from "./WithHeader";

const dashboardService = container.resolve(DashboardService);

export interface DashboardPanelProps {
  dashboardPanelId: string;
}

export const DashboardPanel = observer((props: DashboardPanelProps) => {
  const { dashboardPanelId } = props;
  const data = get(dashboardService, ['selectedDashboard', 'data', 'panels', dashboardPanelId], {});

  let panel;
  switch (data?.type) {
    case "CounterPanel": {
      panel = <CounterPanel dashboardPanelId={dashboardPanelId} />;
      break;
    }
    case "CounterStatistic": {
      panel = <CounterStatistic dashboardPanelId={dashboardPanelId} />;
      break;
    }
    default: {
      const title = `${capitalize(panelTitles.base)} не настроена`
      panel = <div style={{ padding: '0 .5em' }}>{title}</div>;
    }
  }

  return (
    <WithHeader
      id={dashboardPanelId}
    >
      {panel}
    </WithHeader>
  );
});
