export const LOG_SETTINGS_LEVEL_OPTIONS = [
  {
    value: 'debug',
    label: 'Сохраняется подробный отчет по работе блока',
  },
  {
    value: 'info',
    label: 'Сохраняется только информация о входящем сообщении и исходящем',
  },
  {
    value: 'input',
    label: 'Сохраняется только информация о входящем сообщении',
  },
  {
    value: 'error',
    label: 'Сохраняется подробный отчет только в случае ошибки, остальные данные не сохраняются',
  },
  {
    value: 'mini_error',
    label: 'Сохраняется короткий отчет только в случае ошибки, остальные данные не сохраняются',
  },
  {
    value: 'no',
    label: 'Не сохранять информацию',
  },
];

export const LOG_SETTINGS_DATA_LEVEL_OPTIONS = [
  {
    value: 'original',
    label: 'Данные сохраняются без изменений',
  },
  {
    value: 'trash',
    label: 'Сохраняется длина строк, чисел, но значения подменяются на случайные',
  },
  {
    value: 'minimal',
    label: 'Сохраняется тип данных, значение меняется на наименьшее по размеру',
  },
];

export const LOG_SETTINGS_EXCLUDE_LOG_OPTIONS = [
  'body', 'headers', 'pathParams', 'queryParams', 'context'
];

//
// export const LOG_SETTINGS_EXCLUDE_LOG_OPTIONS = [
//   {
//     value: 'body',
//     label: 'Тело сообщения',
//   },
//   {
//     value: 'headers',
//     label: 'Заголовки',
//   },
//   {
//     value: 'pathParams',
//     label: 'Параметры пути',
//   },
//   {
//     value: 'queryParams',
//     label: 'Параметры запроса',
//   },
//   {
//     value: 'context',
//     label: 'Контекст',
//   },
// ];
