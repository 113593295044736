import { ReactFlow, useReactFlow } from "reactflow";
import React, { useCallback, WheelEvent } from "react";

export interface FlowPlatformComponentProps {
  children?: any;
}

export const FlowPlatformComponent = ({ children, ...props }: FlowPlatformComponentProps) => {
  const { setViewport, getViewport, zoomIn, zoomOut } = useReactFlow();

  const handleWheelTrace = useCallback((
    event: WheelEvent<HTMLDivElement>,
  ) => {
    const viewport = getViewport();
    if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
      setViewport({...viewport, x: viewport.x + event.deltaX * 2.0, y: viewport.y - event.deltaY / 2.0});
      return;
    }
    if (event.ctrlKey || event.metaKey) {
      if (event.deltaY < 0) zoomIn();
      else zoomOut();
      return;
    }
    // event.shiftKey
    setViewport({...viewport, x: viewport.x - event.deltaY / 2.0, y: viewport.y - event.deltaX * 2.0});
  }, [getViewport, setViewport, zoomIn, zoomOut]);

  return (
    <ReactFlow
      zoomOnScroll={false}
      zoomOnPinch={false}
      onWheel={handleWheelTrace}
      {...props}
    >
      {children}
    </ReactFlow>
  );
}