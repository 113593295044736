import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import { WDay } from "@/entities/Dashboard/types";
import { parse } from "@/entities/Dashboard/widgets/DashboardPlatform/components/HistoryManager/datemath";

@singleton()
export class PeriodStore {

  period: [WDay, WDay];

  constructor() {
    this.period = [parse('now-1m'), parse('now', true)];

    makeAutoObservable(this);
  }

}
