import { container } from "tsyringe";
import dayjs from "dayjs";
import { BlockMiniService } from "@/entities/Block/services/BlockMiniService";
import styles from "./GraphTooltip.module.scss"

const blockMiniService = container.resolve(BlockMiniService);

export interface GraphTooltipProps {
  active?: boolean;
  payload?: object[];
  label?: number;
}

export const GraphTooltip = ({ active, payload, label } : GraphTooltipProps) => {// props not used

  const blockMini = blockMiniService.miniBlocks;
  const date = dayjs(label*1000).format("DD.MM.YYYY HH:mm:ss")
  const getBlockName = (id: string) => {
    const block = blockMini.entities[id];
    return `${block.flowName} - ${block.name}`
  }

  if (!active || !blockMini || !payload?.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      {payload.map((itm) => (
        <p
          key={itm.name}
          className={styles.label}
        >
          {`${getBlockName(itm.name)} : ${itm.value}`}
        </p>
      ))}
      <p>
        {`Время: ${date}`}
      </p>
    </div>
  );
};