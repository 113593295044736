import React from "react";
import { useNavigate } from "react-router-dom";

import { EnterOutlined } from "@ant-design/icons";
import { Menu, MenuItem } from "@mui/material";
import { container } from "tsyringe";

import { BlockMiniService } from "@/entities/Block/services/BlockMiniService";
import { SearchParams } from "@/hooks/useTemplateSearchParams";


const blockMiniService = container.resolve(BlockMiniService);

interface LegendItemProps {
  id: string;
}

export const LegendItem = ({
  id,
}: LegendItemProps) => {
  const navigate = useNavigate();
  const { miniBlocks } = blockMiniService;
  const block = miniBlocks.entities[id];
  const label = `${block.flowName} - ${block.name}`;
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : null,
    );
  };
  const handleClose = () => setContextMenu(null);
  const paramsObj = { [SearchParams.ShowBlockForm]: true, [SearchParams.BlockId]: block.id };
  // @ts-ignore
  const href = `../flow/${block.flowId}?${(new URLSearchParams(paramsObj)).toString()}`;
  const handleRedirect = () => {
    navigate(href);
  };

  return (
    <span
      onContextMenu={handleContextMenu}
      style={{ cursor: 'context-menu' }}
    >
      {block.name}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={handleRedirect}
          style={{ paddingLeft: '.4em' }}
        >
          <EnterOutlined style={{ fontSize: '1em', marginRight: '1em' }} />
          {`Перейти к: ${label}`}
        </MenuItem>
      </Menu>
    </span>
  )
}
