import React, { FC, useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import { ObjectId } from 'bson';
import Stack from '@mui/system/Stack';
import { container } from 'tsyringe';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { Fields } from '@/shared/ui/Fields/Fields';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { useResolve } from '@/hooks/useResolve';
import { parseConnectFormDataToConnect } from '@/entities/Connect/utils/parseConnectFormDataToConnect';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import {
  CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { ConnectorUnsavedChangesService } from '@/entities/Flow/features/api/ConnectorUnsavedChangesService';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { Input } from '@/shared/ui';
import { NotFoundConnectorService } from '@/features/NotFoundConnector';
import { RegistrableValues } from '@/shared/lib/types';
import { notify } from '@/shared/ui/Toast/notify';
import { RunOnKeys } from '@/shared/ui/RunOnKeys/RunOnKeys';
import { KEYBOARD_KEYS } from '@/shared/lib/constants';
import { ConnectTypes } from '@/entities/Connect/components/ConnectList/types';
import { IndexedDBStore } from '@/store/IndexedDBStore/IndexedDBStore';
import { IndexedDBService } from '@/service/indexedDB';
import { ValidationConnectService } from '../../services/ValidationConnectService';
import Button from '../../../../shared/ui/Button';
import { SkeletonConnectService } from '../../services/SkeletonConnectService';
import { ConnectService } from '../../services/ConnectService';
import { Connect } from '../../types';

import styles from './ConnectFormContainer.module.scss';
import {FieldInputNumber} from "@/shared/ui/Fields/components/FieldInputNumber/FieldInputNumber";

const debounceFormChange = _.debounce(async (trigger: () => void) => {
  trigger();
}, 700);

const joyrideService = container.resolve(JoyRideService);
const notFoundConnectorService = container.resolve(NotFoundConnectorService);

export const ConnectFormContainer: FC = observer(() => {
  const { remove, get, has } = useSearchParamsTemplate();
  const connectorName = get(SearchParams.ConnectorName);
  const connectorType = get(SearchParams.ConnectorType);
  const blockId = get(SearchParams.BlockId);
  const connectorId = get(SearchParams.ConnectorId);

  const isTracingOpen = has(SearchParams.ShowMessageTracing);
  const indexedDBStore = useResolve(IndexedDBStore);
  const indexedDBService = useResolve(IndexedDBService);
  const validationConnectService = useResolve(ValidationConnectService);
  const connectService = useResolve(ConnectService, { [RegistrableValues.BlockId]: blockId });
  const connectorUnsavedChangesService = useResolve(ConnectorUnsavedChangesService);
  const skeletonConnectService = useResolve(SkeletonConnectService, {
    [RegistrableValues.ConnectorName]: connectorName,
    [RegistrableValues.ConnectorType]: connectorType,
  });
  const idbConnectName: string = indexedDBStore?.connectName;
  const idbValue: Connect = JSON.parse(JSON.stringify(indexedDBStore.indexedDbValues));
  const keyForIndexedDB = `fieldData${blockId}.${connectorName}`;
  const connect = parseConnectFormDataToConnect({
    connect: connectService.getFormValues(connectorType, connectorId)!,
    connectType: connectorType,
    blockId,
    connectName: connectorName,
  });
  const [indexDBValues, setIndexDBValues] = useState<Connect>(connect);
  const formValues = useCallback(
    () => (keyForIndexedDB === idbConnectName && idbValue ? idbValue : indexDBValues),
    [keyForIndexedDB, idbConnectName, idbValue]
  );

  useEffect(() => {
    indexedDBService.getFieldData(keyForIndexedDB);
  }, [keyForIndexedDB]);

  useEffect(() => {
    joyrideService.pause();
    skeletonConnectService.getSkeleton(connect).then(() => {
      joyrideService.unpause();
    });
  }, []);

  useEffect(() => {
    if (connectorId) {
      notFoundConnectorService.checkConnectorExist(blockId, connectorId, connectorType);
    }
  }, []);

  useEffect(() => {
    validationConnectService.clear();
    if (connectorId) {
      notFoundConnectorService.checkConnectorExist(blockId, connectorId, connectorType);
    }
  }, []);

  const { handleSubmit, trigger, register, control, formState, setValue, getValues } =
    useForm<Connect>({
      resolver: async (values, context, options) => {
        await validationConnectService.validateConnectForm(connectorType, values);
        connectorUnsavedChangesService.checkChanges(values, connectorType, connectorId);
        const resolved = await yupResolver(validationConnectService.validationSchema)(
          values,
          context,
          options
        );
        setIndexDBValues(values);
        return {
          values,
          errors: { ...resolved.errors, ...validationConnectService.errors },
          warnings: { ...validationConnectService.warnings },
        };
      },
      values: formValues(),
    });

  const handleFormChange = () => {
    debounceFormChange(trigger);
  };

  const isActive: boolean = getValues('isActive');
  const description: string = getValues('description');
  const isNotEmptyDescription: boolean = (description && description.length > 0) || false;

  /** Функция  сброса формы при выключении активности  */
  const notifyOnErrors = () => {
    if (!isActive) {
      connectorUnsavedChangesService.areThereChanges = false;
      remove(CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE);
      indexedDBStore.connectName = null;
      indexedDBStore.connector = null;
      indexedDBStore.indexedDbValues = null;
      indexedDBStore.valuesForSaveIdb = null;
      notify.error('  Не удалось сохранить, так как не прошло валидацию');
    }
  };
  const onSubmit = (indexDBValues: Connect) => {
    if (joyrideService.run) {
      joyrideService.updateCurrentStep(joyrideService.stepIndex + 1);
    }
    if (connectorType) {
      if (connectorId) {
        connectService.updateConnect(connectorType, indexDBValues);
      } else {
        connectService.createConnect(connectorType, {
          ...indexDBValues,
          id: new ObjectId().toString(),
        });
        indexedDBService.deleteFieldData(keyForIndexedDB);
        indexedDBStore.connectName = null;
        indexedDBStore.connector = null;
        indexedDBStore.indexedDbValues = null;
        indexedDBStore.valuesForSaveIdb = null;
      }
    }
    connectorUnsavedChangesService.areThereChanges = false;
    remove(CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE);
  };

  if (skeletonConnectService.isLoadingSkeleton) {
    return (
      <Box alignItems='center' justifyContent='center'>
        <LoaderIcon width={24} height={24} />
      </Box>
    );
  }
  const { Control, Enter } = KEYBOARD_KEYS;
  const keysEvents = [
    {
      keys: [Control, Enter],
      event: handleSubmit(onSubmit, notifyOnErrors),
    },
  ];
  const handleFormChangeOnBlur = () => {
    indexedDBStore.valuesForSaveIdb = indexDBValues;
  };

  return (
    <RunOnKeys keysEvents={keysEvents}>
      <form
        className='px-5'
        onChange={handleFormChange}
        onBlur={handleFormChangeOnBlur}
        onSubmit={handleSubmit(onSubmit, notifyOnErrors)}
      >
        <Stack gap={2.5} paddingBottom='100px'>
          <Box
            className={cn(styles.name_decription_container, {
              [styles.compact]: !isNotEmptyDescription,
              [styles.large]: isNotEmptyDescription,
            })}
          >
            <Box className='name_container'>
              <FieldInput
                label='Наименование'
                name='name'
                register={register}
                error={formState.errors.name}
                description='Введите наименование коннектора, длинной не более 100 символов'
                id='nameConnector'
                localeName='name'
              />
            </Box>
            <Box className='description_container'>
              <Input
                label='Описание'
                inputProps={register('description')}
                error={formState.errors?.description?.message}
                tooltip='Введите описание коннектора, длинной не более 1000 символов'
                id='descriptionConnector'
                multiline
                minRows={1}
                maxRows={4}
              />
            </Box>
          </Box>
          <Accordion elevation={0} variant='outlined'>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Дополнительные настройки</AccordionSummary>
            <AccordionDetails>
              <Stack id='infoConnector' spacing={2.5}>
                {connectorType !== ConnectTypes.Input && (
                  <FieldInput
                    label='Лог в начале работы'
                    name='customLogStart.text'
                    register={register}
                    error={formState.errors.customLogStart?.text}
                    description='При начале работы обработчика/коннектора будет выведен следующий текст'
                    id='customLogStart'
                    localeName='customLogStart.text'
                  />
                )}
                <FieldInput
                  label='Лог в конце работы'
                  name='customLogFinish.text'
                  register={register}
                  error={formState.errors.customLogFinish?.text}
                  description='При завершении работы обработчика/коннектора будет выведен следующий текст'
                  id='customLogFinish'
                  localeName='customLogFinish.text'
                />
                {connectorType !== ConnectTypes.Input && (
                  <FieldInput
                    label='Лог в случае ошибки'
                    name='customLogError.text'
                    register={register}
                    error={formState.errors.customLogError?.text}
                    description='В случае ошибки будет выведен следующий текст'
                    id='customLogError'
                    localeName='customLogError.text'
                  />
                )}
                {connectorType === ConnectTypes.Processor && (
                  <FieldSwitch
                    label='Исключить обработчик из трассировки работы блока'
                    name='settings.isExLog'
                    control={control}
                    error={undefined}
                    description='В случае исключения будет отсутствовать логирование данных после выполнения данного обработчика <br><br>В случае ошибки данные обработчика будут залогированы'
                    id='isExLog'
                    localeName='settings.isExLog'
                  />
                )}
                {connectorType !== ConnectTypes.Input && (
                <FieldInputNumber
                  label='Дополнительные попытки выполнения в случае ошибки'
                  name='settings.countRetry'
                  control={control}
                  error={undefined}
                  description='Количество повторных вызовов при возникновении ошибки обработчика/коннектора'
                  id='settings.countRetry'
                  localeName='settings.countRetry'
                  min={0}
                  max={1000}
                />
                )}
                {connectorType === ConnectTypes.Processor && (
                  <FieldInputNumber
                    label='Количество потоков выполнения'
                    name='settings.countThread'
                    control={control}
                    error={undefined}
                    description='Для распараллеливания работы укажите более 1 потока'
                    id='settings.countThread'
                    localeName='settings.countThread'
                    min={1}
                    max={1000}
                  />
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <FieldSwitch
            control={control}
            label='Активность'
            name='isActive'
            defaultValue='true'
            error={formState.errors.isActive}
            id='activeConnector'
            localeName='isActive'
          />
          {skeletonConnectService.skeleton && (
            <Stack id='infoConnector' spacing={2.5}>
              <Fields
                parameters={skeletonConnectService.skeleton.parameterList}
                register={register}
                control={control}
                errors={validationConnectService.errors}
                warnings={validationConnectService.warnings}
                setValue={setValue}
                id='handlerSeconds'
                getValues={getValues}
                updateSkeleton={skeletonConnectService.updateSkeleton.bind(skeletonConnectService)}
              />
              <Fields
                parameters={skeletonConnectService.skeleton.queryParameterList}
                control={control}
                namePrefix='queryParams'
                register={register}
                errors={validationConnectService.errors}
                setValue={setValue}
                id='handlerMessage'
                getValues={getValues}
                updateSkeleton={skeletonConnectService.updateSkeleton.bind(skeletonConnectService)}
              />
            </Stack>
          )}
        </Stack>
        <Stack
          sx={{
            alignItems: isTracingOpen ? 'flex-start' : 'flex-end',
            position: 'absolute',
            bottom: '0',
            opacity: '0.65',
            backdropFilter: 'blur(16px)',
            width: '100%',
            height: '88px',
            zIndex: 4000,
          }}
        />
        <Box
          width={216}
          sx={{
            position: 'absolute',
            [isTracingOpen ? 'left' : 'right']: '16px',
            bottom: '16px',
            zIndex: 5000,
          }}
        >
          <Button disabled={!validationConnectService.isValid} type='submit' id='saveButton'>
            Сохранить
          </Button>
        </Box>
      </form>
    </RunOnKeys>
  );
});
