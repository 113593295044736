import React, { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { RegistrableValues } from '@/shared/lib/types';
import { useParams } from 'react-router-dom';
import { useResolve } from '@/hooks/useResolve';
import { BlockService } from '@/entities/Block/services/BlockService';
import { GuaranteedContextMenu } from '@/widgets/GuaranteedContext/ui/GuaranteedContext';
import { Tag } from '@/shared/ui/Tag/Tag';
import { bindTrigger } from 'material-ui-popup-state';
import { TextWithIndicator } from '@/entities/Block/components/TextWithIndicator/TextWithIndicator';
import { IndicatorTypes } from '@/shared/ui/Indicator/types';
import { PopupState } from 'material-ui-popup-state/hooks';
import { TagSizes } from '@/shared/ui/Tag/types';

export interface IndicatorGuaranteedOrderProps {
  blockId: string;
  type?: string;

  onClick?(): void;
}

export const IndicatorGuaranteedOrder: FC<IndicatorGuaranteedOrderProps> = observer(
  ({ blockId, type, onClick }) => {
    const { flowId } = useParams();
    const blockService = useResolve(BlockService, { [RegistrableValues.FlowId]: flowId });
    const block = blockService.blocks?.entities[blockId];
    const isGuaranteedOrder = block?.isGuaranteedOrder
      ? IndicatorTypes.Success
      : IndicatorTypes.Disabled;

    const handleToggle = useCallback(
      (popupState: PopupState) => () => {
        popupState.close();
      },
      []
    );
    return (
      <GuaranteedContextMenu blockId={blockId} type='isGuaranteedOrder'>
        {(popupState) => (
          <Tag
            role='menuitem'
            className='nodrag'
            size={TagSizes.Small}
            isGuaranteed
            onClick={handleToggle(popupState)}
            onContextMenu={(e) => {
              e.preventDefault();
              bindTrigger(popupState).onClick(e);
            }}
          >
            <TextWithIndicator indicatorType={isGuaranteedOrder} text='Гарантия порядка' />
          </Tag>
        )}
      </GuaranteedContextMenu>
    );
  }
);

IndicatorGuaranteedOrder.displayName = 'IndicatorGuaranteedOrder';
