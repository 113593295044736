export enum ConnectSizes {
  Small = 'small',
  Large = 'large',
}

export enum ConnectTypes {
  Input = 'input',
  Processor = 'processor',
  ExProcessor = 'exProcessor',
  Output = 'output',
}
