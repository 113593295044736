import { FC, useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Observer, observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { ListWithInfiniteScroll } from '@/shared/ui';
import { FlowService } from '@/entities/Flow/services/FlowService';
import { FlowActionBar } from '@/entities/Flow/widgets/FlowActionBar/FlowActionBar';

import { FlowCard } from '../entities';

const flowService = container.resolve(FlowService);

export const FlowList: FC = observer(() => {
  const { flowId } = useParams();
  const navigate = useNavigate();

  const isLoading = flowService.isLoadingFlows;

  const animated = useCallback((key: string) => {
    const fromWidth = { width: 'calc(100% - 50px)' };
    const toWidth = { width: 'calc(100% - 25px)' };
    const props = {
      whileHover: toWidth,
      whileTap: toWidth,
      initial: fromWidth,
      animate: fromWidth,
    };

    if (key === flowId) {
      return {
        ...props,
        initial: toWidth,
        animate: toWidth,
      };
    }
    return props;
  }, [flowId]);

  const handleCardClick = useCallback(
    (flowId: string) => () => {
      if (!flowId) return;
      navigate(flowId);
      flowService.selectedFlowId = flowId;
    },
    [navigate]
  );

  const fetchNextPage = (page: number) => flowService.getFlows(page);
  return (
    <ListWithInfiniteScroll
      animated={animated}
      elementWidth='calc(100% - 50px)'
      elements={flowService.flows.ids}
      lastPage={flowService.totalPages}
      stopAnimatedForKey={flowId}
      activeElementKey={flowId}
      loading={isLoading}
      fetchNextElement={fetchNextPage}
      renderElement={(id, index, rest) => (
        <Observer key={id}>
          {() => (
            <FlowCard
              flowId={id}
              active={Boolean(rest?.active)}
              onClick={handleCardClick(id)}
              actions={<FlowActionBar />}
            />
            )}
        </Observer>
      )}
    />
  );
});
