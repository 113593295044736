import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import { ZoomOutOutlined } from '@ant-design/icons';
import dayjs from "dayjs";

import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Button from '@/shared/ui/Button';
import Tooltip from '@/shared/ui/Tooltip';
import { PeriodService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/PeriodService";
import { useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";

const periodService = container.resolve(PeriodService);


export const ZoomOutBtn = observer(() => {
  const { set } = useSearchParamsTemplate();

  const handleClick = () => {
    const [start, end] = periodService.unixPeriod;
    periodService.fixPeriod();
    const unixNow = dayjs().unix();
    const duration = end - start;
    const halfDuration = Math.floor(duration / 2);
    let from;
    let to;
    if (start <= 0) {
      from = start;
      to = end * 2;
    } else if (end >= unixNow) {
      from = start - duration;
      to = end;
    } else {
      from = start - halfDuration;
      to = end + halfDuration;      
    }
    from = from < 0 ? 0 : from; // no negative timestamp
    to = to > unixNow ? unixNow : to; // to limited by now
    const unixPeriod: [number, number] = [from, to];
    periodService.unixToDayjs(unixPeriod);
    set({ from: String(from), to: String(to) });
  };

  return (
    <Tooltip title="Расширить период" placement="top" disableInteractive>
      <span>
        <Button
          className="zoom-btn"
          variant={ButtonVariants.Secondary}
          size={ButtonSizes.Medium}
          doesUseInFormOrTable
          onClick={handleClick}
        >
          <ZoomOutOutlined style={{ fontSize: '1.7em', color: 'var(--label-text-color)' }} />
        </Button>
      </span>
    </Tooltip>
  );
});
