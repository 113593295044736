import { FC, useCallback } from 'react';
import { Stack } from '@mui/system';
import { useAuth } from 'react-oidc-context';
import { ButtonVariants } from '@/shared/ui/Button/types';
import Button from '@/shared/ui/Button';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';

export const ExitConfirmModal: FC = () => {
  const { has, remove } = useSearchParamsTemplate();
  const auth = useAuth();
  const isOpen = has(SearchParams.ShowExitConfirmModal);

  const handleDialogClose = () => {
    remove([SearchParams.ShowExitConfirmModal]);
  };

  const handleDialogOk = useCallback(() => {
    localStorage.clear();
    auth.removeUser().finally(() => {
      location.reload();
    });
    remove([SearchParams.ShowExitConfirmModal]);
  }, [auth, remove]);

  return (
    <Dialog fullWidth onClose={handleDialogClose} open={isOpen}>
      <DialogTitle onClose={handleDialogClose}>Выйти</DialogTitle>
      <DialogContent>
        <Stack spacing={5}>
          <div>
            <p>
              <span>Вы уверены, что хотите выйти?</span>{' '}
            </p>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleDialogClose}>
          Отмена
        </Button>
        <Button variant={ButtonVariants.Popup} onClick={handleDialogOk}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ExitConfirmModal.displayName = 'ExitConfirmModal';
