import React, { FC, useLayoutEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { AnimatePresence } from 'framer-motion';
import { container } from 'tsyringe';
import { Box, Stack } from '@mui/system';
import { useParams } from 'react-router-dom';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { RegistrableValues } from '@/shared/lib/types';

import { DeleteDashboardModal, DashboardFormDrawer } from '@/entities/Dashboard/features';
import { BlockMiniService } from '@/entities/Block/services/BlockMiniService';
import { DashboardService } from '@/entities/Dashboard/services/DashboardService';
import { FlowService } from "@/entities/Flow/services/FlowService";

import { DashboardPlatform } from '../DashboardPlatform/DashboardPlatform';
import {
  DashboardPanelSettings,
} from '../DashboardPlatform/components/DashboardPanel/DashboardPanelSettings/DashboardPanelSettings';
import { DashboardCreateBtn } from './DashboardCreateBtn';

const dashboardService = container.resolve(DashboardService);
const blockMiniService = container.resolve(BlockMiniService);
const flowService = container.resolve(FlowService);

export const DashboardContent: FC = observer(() => {
  const {dashboardId} = useParams();

  container.register(RegistrableValues.DashboardId, {useValue: dashboardId || ''});

  const addPanel = () => {
    dashboardService.addElementToLayoutDashboard()
  }

  useLayoutEffect(() => {
    blockMiniService.getAllMiniBlocks();
    flowService.getAllFlows();
  }, []);

  useLayoutEffect(() => {
    if (!dashboardId) return;
    dashboardService.getDashboard(dashboardId);
    dashboardService.selectedDashboardId = dashboardId
  }, [dashboardId]);

  if (dashboardService.isLoadingDashboard || blockMiniService.isLodingMiniBlocks) {
    return (
      <Box
        display='grid'
        height='calc(100vh - 96px)'
      >
        <Stack alignItems='center' justifyContent='center'>
          <LoaderIcon width={24} height={24} />
        </Stack>
      </Box>
    );
  }

  return (
    <AnimatePresence key={2} mode='wait'>
      <Box
        position='relative'
        height='calc(100vh - 96px)'
      >
        <Box zIndex={1000} height='100%'>
          {dashboardId && (
            <>
              <DashboardPlatform />
              <DashboardCreateBtn handler={addPanel} />
            </>
          )}
        </Box>
        <Box
          position='absolute'
          height='100%'
          top='0'
          right='0'
        >
          <Box>
            <DeleteDashboardModal />
            <DashboardFormDrawer />
            <DashboardPanelSettings />
          </Box>

        </Box>


      </Box>
    </AnimatePresence>
  );
});
