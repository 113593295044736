import { FC, useCallback, useEffect, useState } from 'react';

import { Fields } from '@/shared/ui/Fields/Fields';
import Button from '@/shared/ui/Button';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { RegistrableValues } from '@/shared/lib/types';
import { ConnectService } from '@/entities/Connect/services/ConnectService';
import { useParams } from 'react-router-dom';
import { BlockService } from '@/entities/Block/services/BlockService';
import { useResolve } from '@/hooks/useResolve';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { container } from 'tsyringe';
import { FlowUpdaterService } from '@/entities/Flow/services/FlowUpdaterService';
import Stack from '@mui/system/Stack';

import { Connect, Parameter } from '../../types';
import { SkeletonConnectService } from '../../services/SkeletonConnectService';
import { ValidationConnectService } from '../../services/ValidationConnectService';
import {Box} from "@mui/system";

const debounceFormChange = _.debounce(async (trigger: () => void) => {
  trigger();
}, 700);

export const CreatingTwoConnectsFormContainer: FC = observer(() => {
  const { remove, get } = useSearchParamsTemplate();
  const { flowId } = useParams();

  container.register(RegistrableValues.FlowId, { useValue: flowId || '' });
  const flowUpdaterService = container.resolve(FlowUpdaterService);

  const [parameterList, setParameterList] = useState<Parameter[]>([]);

  const connectorName = get(SearchParams.ConnectorName);
  const targetId = get(SearchParams.TargetId);
  const sourceId = get(SearchParams.SourceId);

  const connectService = useResolve(ConnectService, { [RegistrableValues.BlockId]: '' });
  const blockService = useResolve(BlockService, { [RegistrableValues.FlowId]: flowId || '' });

  const skeletonConnectService = useResolve(SkeletonConnectService, {
    [RegistrableValues.ConnectorType]: '',
    [RegistrableValues.ConnectorName]: connectorName,
  });

  const validationConnectService = useResolve(ValidationConnectService);

  const {
    register,
    control,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    trigger,
  } = useForm({
    resolver: async (values) => {
      void skeletonConnectService.getSkeletonForBoth(connectorName, {
        connect: values as Connect,
        flow: flowUpdaterService.updatedFlow,
        outputBlockId: sourceId,
        inputBlockId: targetId,
      });

      return { values, errors: validationConnectService.errors };
    },
    // values: validationConnectService.validationSchema?.cast(),
  });

  const onSubmit = useCallback(async () => {
    const values = getValues() as Connect;

    await connectService.createBothConnects(
      sourceId,
      targetId,
      values,
      flowUpdaterService.updatedFlow
    );

    await flowUpdaterService.enrichRelationFlow(flowUpdaterService.updatedFlow)

    remove([
      SearchParams.ConnectorName,
      SearchParams.TargetId,
      SearchParams.SourceId,
      SearchParams.BlockId,
      SearchParams.ShowCreatingTwoConnectsForm,
    ]);

    blockService.isVisiblePanelChooseConnects = false;
    blockService.isVisibleConnectionLine = false;
  }, [
    blockService,
    connectService,
    flowUpdaterService.updatedFlow,
    getValues,
    remove,
    sourceId,
    targetId,
  ]);

  const handleFormChange = () => {
    debounceFormChange(trigger);
  };

  useEffect(() => {
    if (!connectorName) return;

    const values = getValues() as Connect;

    skeletonConnectService
      .getSkeletonForBoth(connectorName, {
        connect: values,
        flow: flowUpdaterService.updatedFlow,
        outputBlockId: sourceId,
        inputBlockId: targetId,
      })
      .then((value) => {
        setParameterList(value.parameterList || []);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorName, getValues, sourceId, targetId]);

  useEffect(() => {
    validationConnectService.clear();
    if (!blockService.blocks?.entities[sourceId] || !blockService.blocks?.entities[targetId]) {
      remove([
        SearchParams.ConnectorName,
        SearchParams.TargetId,
        SearchParams.SourceId,
        SearchParams.BlockId,
        SearchParams.ShowCreatingTwoConnectsForm,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <form className='px-5' onChange={handleFormChange}>
        <input hidden {...register('queryParams', { value: {} })} />
        <input hidden {...register('params', { value: {} })} />
        <input hidden {...register('typeConnect', { value: connectorName })} />
          <Stack id='infoConnector' spacing={2.5}>
            <Fields
              parameters={parameterList}
              register={register}
              control={control}
              errors={errors}
              getValues={getValues}
              // @ts-ignore
              setValue={setValue}
            />
          </Stack>
      </form>
      <Stack alignItems='flex-end' paddingX={2.5} paddingY={2}>
        <Box width={216}>
          <Button
              disabled={!validationConnectService.isValid}
              onClick={handleSubmit(onSubmit)}
          >
            Сохранить
          </Button>
        </Box>
      </Stack>
    </div>
  );
});
