import React, { FC, memo, MouseEvent, useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import cn from 'classnames';
import { container } from 'tsyringe';
import MenuIcon from '@/shared/ui/Icons/MenuIcon';
import { CrossIcon } from '@/shared/ui/Icons/CrossIcon/CrossIcon';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { DashboardService } from '@/entities/Dashboard/services/DashboardService';

import styles from './WithHeader.module.scss';
import actionSearchParams from "@/entities/Dashboard/widgets/DashboardPlatform/constants/actionSearchParams";

const dashboardService = container.resolve(DashboardService);

export interface OpenDashFormProps {
  dashboardPanelId: string;
  title: string;
}

export const Toolbar: FC<OpenDashFormProps> = memo(({ dashboardPanelId, title }) => {
  const { get, set, remove } = useSearchParamsTemplate();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const handler = () => setExpanded(false);
    const { body } = document;
    body.addEventListener('click', handler);
    return () => {
      body.removeEventListener('click', handler);
    }
  }, []);

  const showSettings = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      const qsDashboardPanelId = get(SearchParams.DashboardPanelId);
      remove(actionSearchParams);
      if (qsDashboardPanelId === dashboardPanelId) return;
      set([SearchParams.ShowDashboardPanelSettingsForm, {
        [SearchParams.DashboardPanelId]: dashboardPanelId,
      }]);
    },
    [dashboardPanelId, navigate, remove, set],
  );

  const removePanel = () => {
    dashboardService.deleteDashboardPanel(dashboardPanelId);
    remove(actionSearchParams);
  }

  const items = [
    {
      key: 'settings',
      label: 'настройки',
      icon: <SettingsIcon height='1em' width='1em' />,
      handler: showSettings,
    },
    {
      key: 'remove',
      label: 'удалить',
      icon: <TrashIcon height='1em' width='1em' />,
      handler: removePanel,
    },
  ];


  const triggerIcon = expanded
    ? <CrossIcon height='1em' width='1em' />
    : <MenuIcon height='1em' width='1em' color='disabled' />;

  const showMenu = (e: MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);
  }

  const toolbar = (
    <Button.Group className={cn({ expanded })}>
      {items.map(({ key, label, handler , icon}) => (
        <Tooltip
          key={key}
          title={label}
        >
          <Button
            className='action-btn'
            key={key}
            type='text'
            icon={icon}
            onClick={handler}
          />
        </Tooltip>
      ))}
      <Button
        className='trigger-btn'
        icon={triggerIcon}
        type='text'
        onClick={showMenu}
      />
    </Button.Group>
  );

  return (
    <>
      <div className={styles.name}>
        {!expanded && title}
      </div>
      <div className={styles.settings}>
        {toolbar}
      </div>
    </>
  );
});

Toolbar.displayName = 'ToolbarDashboardPanel';
