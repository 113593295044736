import { FC } from 'react';

import Stack from '@mui/system/Stack';
import Tooltip from '@/shared/ui/Tooltip';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { DownloadIcon } from '@/shared/ui/Icons/DownloadIcon/DownloadIcon';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { transformBytesToKb } from '@/shared/lib/transformBytesToKb';

import styles from './FileCard.module.scss';
import { observer } from 'mobx-react-lite';

export interface FileCardProps extends Pick<File, 'name' | 'size'> {
  isNew?: boolean;
  loading?: boolean;
  downloadLink?: string;
  isRemove?: boolean;
  type?: string;

  onRemove(): void;
}

export const FileCard: FC<FileCardProps> = observer(
  ({ name, size, isNew, loading, downloadLink, isRemove = true, onRemove, type }) => {
    if (loading) {
      return (
        <Stack className={styles.root} gap={1} alignItems='center' justifyContent='center'>
          <LoaderIcon />
        </Stack>
      );
    }

    const renderContent = () => {
      if (type === 'image/jpeg' || type === 'image/webp') {
        return (
          <Stack className={styles.image}>
            <img src={downloadLink} alt={name} />
          </Stack>
        );
      }
      if (type === 'video/mp4') {
        return (
          <Stack className={styles.image}>
            <video controls>
              <source src={downloadLink} type='video/mp4' />
            </video>
          </Stack>
        );
      }
    };

    return (
      <Stack className={styles.root} gap={1}>
        {isNew && (
          <div className={styles.ribbon}>
            <span>новый</span>
          </div>
        )}
        <Stack gap={0.5}>
          <p className={styles.title}>{name}</p>
        </Stack>
        {renderContent()}
        <Stack direction='row' justifyContent='space-between'>
          <Stack direction='row' gap={2} alignItems='center'>
            <p className={styles.description}>{transformBytesToKb(size)} Kb</p>
          </Stack>
          <Stack direction='row' gap={1} zIndex={500}>
            <>
              {downloadLink && (
                <Tooltip title='Скачать'>
                  <a href={downloadLink}>
                    <Button circle size={ButtonSizes.Small} variant={ButtonVariants.Outlined}>
                      <DownloadIcon />
                    </Button>
                  </a>
                </Tooltip>
              )}
              {isRemove && (
                <Tooltip title='Удалить'>
                  <span>
                    <Button
                      circle
                      size={ButtonSizes.Small}
                      variant={ButtonVariants.Outlined}
                      onClick={onRemove}
                    >
                      <TrashIcon />
                    </Button>
                  </span>
                </Tooltip>
              )}
            </>
          </Stack>
        </Stack>
      </Stack>
    );
  }
);

FileCard.displayName = 'FileCard';
