import React, { FC, useCallback } from 'react';

import MenuItem from '@mui/material/MenuItem';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { RegistrableValues } from '@/shared/lib/types';
import { useParams } from 'react-router-dom';
import { useResolve } from '@/hooks/useResolve';
import { BlockService } from '@/entities/Block/services/BlockService';

export interface ToggleConnectorMenuItemProps {
  blockId: string;
  type?: string;

  onClick?(): void;
}

export const ToggleGuaranteedMenuItem: FC<ToggleConnectorMenuItemProps> = observer(
  ({ blockId, type, onClick }) => {
    container.register(RegistrableValues.BlockId, { useValue: blockId });
    const { flowId } = useParams();
    const typeTitle = type === 'isGuaranteedDelivery' ? 'Гарантия доставки' : 'Гарантия порядка';
    const blockService = useResolve(BlockService, { [RegistrableValues.FlowId]: flowId });
    const block = blockService.blocks?.entities[blockId];
    const isGuaranteedDelivery = blockService.blocks?.entities[blockId]?.isGuaranteedDelivery;
    const isGuaranteedOrder = blockService.blocks?.entities[blockId]?.isGuaranteedOrder;

    const notifySetMes = (isActive: boolean) =>
      isActive ? notify.success(`${typeTitle} выключена`) : notify.success(`${typeTitle} включена`);
    const guaranteedDeliveryHandler = () => {
      if (isGuaranteedDelivery === true) {
        blockService.updateBlock({
          ...block,
          isGuaranteedDelivery: false,
          isActiveGuaranteedDelivery: false,
          isGuaranteedOrder: false,
        });
      }
      if (isGuaranteedDelivery === false) {
        blockService.updateBlock({
          ...block,
          isGuaranteedDelivery: true,
          isActiveGuaranteedDelivery: true,
        });
      }

      notifySetMes(isGuaranteedDelivery);
      onClick?.();
    };

    const guaranteedOrderHandler = () => {
      if (isGuaranteedOrder === true) {
        blockService.updateBlock({
          ...block,
          isGuaranteedOrder: false,
        });
      }
      if (isGuaranteedOrder === false) {
        blockService.updateBlock({
          ...block,
          isGuaranteedOrder: true,
          isActiveGuaranteedDelivery: true,
          isGuaranteedDelivery: true,
        });
      }
      notifySetMes(isGuaranteedOrder);
      onClick?.();
    };

    const handleClick = useCallback(() => {
      switch (type) {
        case 'isGuaranteedDelivery':
          return guaranteedDeliveryHandler();
        case 'isGuaranteedOrder':
          return guaranteedOrderHandler();
        default:
          return null;
      }
    }, [isGuaranteedDelivery, isGuaranteedOrder, blockId, block, type, blockService, onClick]);

    return (
      <MenuItem onClick={handleClick}>
        <Stack direction='row' gap={2.5}>
          <Typography>Включить / выключить</Typography>
        </Stack>
      </MenuItem>
    );
  }
);

ToggleGuaranteedMenuItem.displayName = 'ToggleGuaranteedMenuItem';
