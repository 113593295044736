import { Node } from 'reactflow';
import { MessageNodeProps } from '@/entities';
import { AllConnectService } from "@/entities/Connect/services/AllConnectService";
import { DisplayMessageService } from '@/entities/Connect/services/DisplayMessageService';

export function getNodesForMessageTracing(
  displayMessageService: DisplayMessageService,
  allConnectService: AllConnectService,
): Node<MessageNodeProps>[] {
  const { exchangeList, canvas } = displayMessageService;
  if (!exchangeList) return [];
  const nodes = exchangeList?.ids.map((id) => {
    const exchangeData = exchangeList!.entities[id];
    const { type } = exchangeData
    const node = {
      id,
      type: 'messageNode',
      style: {
        width: canvas?.entities[id]?.sx?.width || 420,
        height: canvas?.entities[id]?.sx?.height || 420,
        display: 'flex',
      },
      position: {
        x: canvas?.entities[id]?.left || 0,
        y: canvas?.entities[id]?.top || 0,
      },
    }
    const buildDownloadHandler = (type: string) => {
      const { bodyDataComponentId, isSuccessDownload } = exchangeData[type] || {};
      if (!bodyDataComponentId || isSuccessDownload) return undefined;
      return () => {
        displayMessageService.downloadBodyData(id, bodyDataComponentId, type);
      };
    }
    const data = {
      id,
      value: exchangeData.body?.outputBody || '',
      headers: exchangeData.headers,
      pathParams: exchangeData.inputPathParams,
      queryParams: exchangeData.inputQueryParams,
      exception: exchangeData.exception,
      stackTrace: exchangeData.stackTrace,
      messageId: '',
      dateExecute: exchangeData.timeStart ? new Date(exchangeData.timeStart).toISOString() : undefined,
      timeExecute: exchangeData.timeExecute,
      valueSize: exchangeData.body?.size || 0,
      onDownloadBody: buildDownloadHandler('body'),
    };
    let processorType = '';
    let processorTypeLocale = '';
    let cfg;
    switch (type) {
      case 'start':
        processorType = exchangeData.inputConnect?.typeConnect || '';
        processorTypeLocale = allConnectService.parameterInputByName(processorType)?.localeName;
        cfg = {
          processorName: `Входящее сообщение ${exchangeData.inputConnect?.name || ''}`,
          processorType,
          processorTypeLocale,
          value: exchangeData.inputBody?.outputBody || '',
          context: exchangeData.context?.outputBody || '',
          headers: exchangeData.inputHeaders,
          files: exchangeData.files,
          valueSize: exchangeData.inputBody?.size || 0,
          onDownloadBody: buildDownloadHandler('inputBody'),
        };
        break;
      case 'work':
        processorType = exchangeData.processor?.typeProcessor || '';
        processorTypeLocale = allConnectService.parameterProcessorByName(type)?.localeName;
        cfg = {
          processorName: exchangeData.processor?.name || '',
          processorType,
          processorTypeLocale,
          context: exchangeData.context?.outputBody || '',
          messageId: exchangeData?.headers?.messageId || '',
        };
        break;
      case 'finish':
        cfg = {
          processorName: 'Исходящее сообщение',
          processorType: '',
          headers: exchangeData.outputHeaders,
        };
        break;
      default:
        cfg = {
          processorName: exchangeData.processor?.name || '',
          processorType: exchangeData.processor?.typeProcessor || '',
          messageId: exchangeData?.headers?.messageId || '',
        };
        break;
    }
    return {
      ...node,
      data: {
        ...data,
        ...cfg,
      }
    }
  });

  return nodes;
}
