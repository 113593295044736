import React, { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { RegistrableValues } from '@/shared/lib/types';
import { useParams } from 'react-router-dom';
import { useResolve } from '@/hooks/useResolve';
import { BlockService } from '@/entities/Block/services/BlockService';
import { GuaranteedContextMenu } from '@/widgets/GuaranteedContext/ui/GuaranteedContext';
import { Tag } from '@/shared/ui/Tag/Tag';
import { bindTrigger } from 'material-ui-popup-state';
import { TextWithIndicator } from '@/entities/Block/components/TextWithIndicator/TextWithIndicator';
import { IndicatorTypes } from '@/shared/ui/Indicator/types';
import { PopupState } from 'material-ui-popup-state/hooks';
import { TagSizes } from '@/shared/ui/Tag/types';
import { ConsumerInformationBadge } from '@/entities/ConsumerInformation';

export interface IndicatorGuaranteedDeliveryProps {
  blockId: string;
  type?: string;

  onClick?(): void;
}

export const IndicatorGuaranteedDelivery: FC<IndicatorGuaranteedDeliveryProps> = observer(
  ({ blockId }) => {
    const { flowId } = useParams();
    const blockService = useResolve(BlockService, { [RegistrableValues.FlowId]: flowId });
    const block = blockService.blocks?.entities[blockId];

    const isGuaranteedDelivery = block?.isGuaranteedDelivery
      ? IndicatorTypes.Success
      : IndicatorTypes.Disabled;

    const handleToggle = useCallback(
      (popupState: PopupState) => () => {
        popupState.close();
      },
      []
    );
    return (
      <GuaranteedContextMenu blockId={blockId} type='isGuaranteedDelivery'>
        {(popupState) => (
          <ConsumerInformationBadge id={`${block?.id}_000000000000000000000000`} color='secondary'>
            <Tag
              role='menuitem'
              className='nodrag'
              size={TagSizes.Small}
              isGuaranteed
              onClick={handleToggle(popupState)}
              onContextMenu={(event) => {
                event.preventDefault();
                bindTrigger(popupState).onClick(event);
              }}
            >
              <TextWithIndicator indicatorType={isGuaranteedDelivery} text='Гарантия доставки' />
            </Tag>
          </ConsumerInformationBadge>
        )}
      </GuaranteedContextMenu>
    );
  }
);

IndicatorGuaranteedDelivery.displayName = 'IndicatorGuaranteedDelivery';
